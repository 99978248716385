import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import envConfig from '~/configs/env'

type ExchangeTokenResponse = {
  access_token: string
  id_token: string
  refresh_token: string
}

type ExchangeTokenRequest = {
  code: string
}

const { apiUrl } = envConfig

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl }),
  endpoints: (builder) => ({
    exchangeToken: builder.mutation<ExchangeTokenResponse, ExchangeTokenRequest>({
      query: (request) => ({
        url: '/api/exchange-token',
        method: 'POST',
        body: request
      })
    })
  })
})

export const { useExchangeTokenMutation } = authApi
