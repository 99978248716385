import { compact } from 'lodash'
import { useMemo } from 'react'
import { useTitle } from 'react-use'

import getCustomerAssets from '~/auth/assets'

type TitleComponent = string | null | undefined

/**
 * Sets the page title using the provided title components. Components are combined with a dash
 * separator. The first component is always "Synop".
 *
 * @example
 * usePageTitle('Dashboard') // Sets the page title to "Synop - Dashboard"
 * usePageTitle('Dashboard', 'Users') // Sets the page title to "Synop - Dashboard - Users"
 * usePageTitle('Chargers', chargerName) // Sets the page title to "Synop - Chargers - {chargerName}"
 * usePageTitle('Chargers', null) // Sets the page title to "Synop - Chargers"
 */
export default function usePageTitle(...components: TitleComponent[]) {
  const topLevelTitle = useMemo(() => {
    const asset = getCustomerAssets()
    return asset?.title ?? process.env.NEXT_PUBLIC_PAGE_TITLE ?? 'Synop'
  }, [])

  const title = compact([topLevelTitle, ...components]).join(' - ')
  useTitle(title)
}
