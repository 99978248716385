import type { WithAuthenticatorProps } from '@aws-amplify/ui-react'
import { ThemeProvider, withAuthenticator } from '@aws-amplify/ui-react'
import { datadogRum } from '@datadog/browser-rum'
import { Amplify } from 'aws-amplify'
import type { AuthUser } from 'aws-amplify/auth'
import { signOut } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import { isEqual } from 'lodash'
import { useRouter, useSearchParams } from 'next/navigation'
import type { PropsWithChildren } from 'react'
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { LoadingMessage } from '~/components/Loaders'
import config from '~/configs/env'
import { useCurrentUser, useUser } from '~/data/api/hooks/users'
import useLogger from '~/debug/logger'
import useFirstRender from '~/hooks/useFirstRender'
import { clearTimezoneSnooze } from '~/layouts/components/banners/TimezoneBanner'
import { usePrincipalOrgId, useSetPrincipleOrganization } from '~/store/slices/currentOrg'

import AuthCustomizations, { useAuthTheme } from './AuthCustomizations'

import '@aws-amplify/ui-react/styles.css'

if (config.datadog) datadogRum.init(config.datadog)

export type UserDataType = {
  email: string
  id: string
  name: string
  role?: string
  sites?: string[]
  organizationId?: string
}

type AuthValuesType = {
  loading: boolean
  logout: () => void
  user: UserDataType | null
}

// ** Defaults
const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  logout: () => null
}

const AuthContext = createContext(defaultProvider)

type Props = PropsWithChildren<WithAuthenticatorProps>

const LOADING_PLACEHOLDER = 'loading'

const AuthProvider = ({ children, user: amplifyUser }: Props) => {
  const [user, isLoading] = useConsolidatedUser(amplifyUser)
  const { synopUser } = useCurrentUser()
  const principalOrgId = usePrincipalOrgId()
  const setPrincipalOrganization = useSetPrincipleOrganization()

  // Set the Principal Org on login
  useEffect(() => {
    if (synopUser?.organizationId !== principalOrgId) {
      setPrincipalOrganization(synopUser?.organizationId)
    }
  }, [synopUser, principalOrgId, setPrincipalOrganization])

  // By the time this code is executed, we have already authenticated with Cognito. However, we need to wait for
  // the `useUser` hook to finish before we can set the user in the context. While we are waiting for that request
  // to complete, we need to set a flag in localStorage to indicate that the user is logged in, so that the
  // `AuthGuard` doesn't redirect the user to the index page.
  useFirstRender(() => {
    const userData = window.localStorage.getItem('userData')
    window.localStorage.setItem('userData', userData ?? LOADING_PLACEHOLDER)
  })

  const handleLogout = useCallback(() => {
    // Sign out from Cognito
    signOut()

    // Stop Datadog session replay recording
    datadogRum.stopSessionReplayRecording()

    // Remove the user from localStorage
    window.localStorage.removeItem('userData')

    // Reload the page to reset the state. TODO: I would like to redirect to the index route, but the `AclGuard`
    // keeps redirecting to `/home` instead.
    window.location.reload()
  }, [])

  // Sign out the user if Amplify fails to refresh the token
  useEffect(() => {
    const remove = Hub.listen('auth', (data) => {
      if (data.payload.event === 'tokenRefresh_failure') {
        handleLogout()
      }
    })

    return () => {
      remove()
    }
  }, [handleLogout])

  const values = useMemo<AuthValuesType>(
    () => ({
      user,
      loading: isLoading,
      logout: handleLogout
    }),
    [user, handleLogout, isLoading]
  )

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

const Authenticator = withAuthenticator(AuthProvider, { hideSignUp: true, components: AuthCustomizations })
export default function AuthenticatorWrapper({ children }: PropsWithChildren) {
  const theme = useAuthTheme()
  // Add this flag to check if the Refresh flag is set by the sso flow
  // So we can refresh and pick up the currently logged in user before
  // showing them an unnessecary log in screen
  const [shouldRender, setShouldRender] = useState(false)
  useFirstRender(() => {
    const userData = window.localStorage.getItem('userData')
    if (userData === 'refresh') {
      window.localStorage.removeItem('userData')
      window.location.reload()
    } else {
      setShouldRender(true)
    }
  })

  return shouldRender ? (
    <ThemeProvider theme={theme}>
      <Authenticator>{children}</Authenticator>
    </ThemeProvider>
  ) : (
    <LoadingMessage />
  )
}

export { AuthContext }

/**
 * Consolidates the Amplify user object and the user data from the database into a single object, and
 * stores it in localStorage.
 *
 * This also initiates user session recording with Datadog.
 */
function useConsolidatedUser(amplifyUser?: AuthUser) {
  const logger = useLogger('AuthProvider')
  const storedUser = useStoredUser()
  const { user: dbUser, isLoading } = useUser(amplifyUser?.userId)

  const sessionStarted = useRef(false)
  const [user, setUser] = useState<UserDataType | null>(storedUser)
  const router = useRouter()
  const searchParams = useSearchParams()

  // Begin Datadog session recording when the user is logged in
  useEffect(() => {
    if (amplifyUser && user && !sessionStarted.current) {
      sessionStarted.current = true
      logger.info('Starting Datadog session recording')
      datadogRum.setUser({
        id: user.id,
        email: user.email,
        name: user.name,
        organizationId: user.organizationId
      })
      datadogRum.startSessionReplayRecording()
    }
  }, [amplifyUser, logger, user])

  // When the user is logged in and we have their data from the database, store the data in localStorage.
  useEffect(() => {
    if (amplifyUser && dbUser) {
      const userData: UserDataType = {
        email: dbUser.email!,
        id: amplifyUser.userId,
        name: dbUser.name ?? '',
        role: dbUser.roles?.at(0)?.toLowerCase(),
        sites: dbUser.sites,
        organizationId: dbUser.organizationId
      }

      // If no user object was present in local storage, then the user has just logged in. We need to
      // clear the timezone snooze state.
      if (!user) {
        clearTimezoneSnooze(userData.id)
      }

      if (isEqual(user, userData)) return

      setUser(userData)
      window.localStorage.setItem('userData', JSON.stringify(userData))

      // If there is a `returnUrl` send the user to it, otherwise send them to the root
      const returnUrl = searchParams?.get('returnUrl')
      if (returnUrl) {
        router.replace(returnUrl)
      }
    }
    // Do not re-run the effect when the search params change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amplifyUser, dbUser, router, user])

  return [user, isLoading] as const
}

/**
 * Checks if there is user data stored in the localStorage `userData` field, verifies that it is valid, and returns
 * it if so. Otherwise, returns null.
 */
function useStoredUser(): UserDataType | null {
  const storedUserStr = window.localStorage.getItem('userData')
  return useMemo(() => {
    if (!storedUserStr) return null

    let storedUser: unknown
    try {
      storedUser = JSON.parse(storedUserStr)
    } catch (e) {
      return null
    }

    if (typeof storedUser !== 'object' || storedUser === null) return null

    // Verify that the saved object is valid
    const fieldsToCheck: Array<keyof UserDataType> = ['email', 'name', 'id', 'role']
    const hasAllFields = fieldsToCheck.every((field) => {
      return (
        Object.hasOwnProperty.call(storedUser, field) &&
        typeof (storedUser as Record<string, unknown>)[field] === 'string'
      )
    })

    if (hasAllFields) {
      return storedUser as UserDataType
    } else {
      return null
    }
  }, [storedUserStr])
}

/**
 * Returns the user data from localStorage parsed into a `UserDataType` object. This makes user data
 * available to components high in the component tree, outside the `AuthProvider` or `ReduxProvider`.
 * In particular, the `Rollbar` component needs this information to send identifying information in
 * error reports.
 */
export function getUserData() {
  const data = window.localStorage.getItem('userData')
  if (!data || data === LOADING_PLACEHOLDER) return null

  try {
    return JSON.parse(data) as UserDataType
  } catch (e) {
    return null
  }
}

/**
 * The Amplify clientId needs to be set dynamically because the clientId could be for SSO users, or
 * a regular user. There are different clientIds depending on the auth type.
 */
if (typeof window !== 'undefined') {
  // Find Cognito client ID from localStorage
  const clientIdFromStorage = Object.keys(window.localStorage)
    .find((key) => key.includes('CognitoIdentityServiceProvider'))
    ?.split('.')[1]
  Amplify.configure(
    clientIdFromStorage ? { ...config.amplify, aws_user_pools_web_client_id: clientIdFromStorage } : config.amplify
  )
}
