'use client'

import { CacheProvider } from '@emotion/react'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { LicenseInfo } from '@mui/x-license'
import { usePathname } from 'next/navigation'
import 'prismjs'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import AclGuard from '@core/components/auth/AclGuard'
import AuthGuard from '@core/components/auth/AuthGuard'
import { SettingsConsumer, SettingsProvider } from '@core/context/settingsContext'
import ThemeComponent from '@core/theme/ThemeComponent'
import { createEmotionCache } from '@core/utils/create-emotion-cache'

import SSOCallbackPage from '~/app/auth/callback/page'
import AuthProvider from '~/auth/AuthProvider'
import getCustomerAssets from '~/auth/assets'
import { LoadingMessage } from '~/components/Loaders'
import Snackbar from '~/components/Snackbar'
import { defaultACLObj } from '~/configs/acl'
import UserLayout from '~/layouts/UserLayout'
import Rollbar from '~/layouts/errors/Rollbar'
import { persistor, store } from '~/store'

const emotionCache = createEmotionCache()
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_LICENSE_KEY ?? '')

// ** Configure JSS & ClassName
export default function AppContainer({ children }: { children: ReactNode }) {
  const pathname = usePathname()
  useEffect(() => {
    const { favicon } = getCustomerAssets() ?? {}
    if (favicon) {
      const iconLink = document.querySelector("link[rel~='icon']")
      iconLink?.setAttribute('href', favicon)
    }
  }, [pathname])

  const AppPages = pathname.includes('auth/callback') ? (
    <SSOCallbackPage />
  ) : (
    <AuthProvider>
      <AuthGuard fallback={<LoadingMessage />}>
        <AclGuard aclAbilities={defaultACLObj}>
          <UserLayout contentHeightFixed={false}>{children}</UserLayout>
        </AclGuard>
      </AuthGuard>
      <Snackbar />
    </AuthProvider>
  )

  return (
    <Rollbar>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistor}>
          <CacheProvider value={emotionCache}>
            <SettingsProvider>
              <SettingsConsumer>
                {({ settings }) => {
                  return (
                    <ThemeComponent settings={settings}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>{AppPages}</LocalizationProvider>
                    </ThemeComponent>
                  )
                }}
              </SettingsConsumer>
            </SettingsProvider>
          </CacheProvider>
        </PersistGate>
      </ReduxProvider>
    </Rollbar>
  )
}
