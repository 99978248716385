import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js'
import { Amplify } from 'aws-amplify'
import { decodeJWT } from 'aws-amplify/auth'

import config from '~/configs/env'

export default async function setUserTokens(accessToken: string, idToken: string, refreshToken: string) {
  const { payload: accessTokenPayload } = decodeJWT(accessToken)
  const { sub, client_id, username } = accessTokenPayload

  if (client_id && sub) {
    Amplify.configure({ ...config.amplify, aws_user_pools_web_client_id: client_id })

    await setupAmplifySession(
      username as string,
      accessToken,
      idToken,
      refreshToken,
      config.amplify.aws_user_pools_id,
      client_id as string
    )
  } else {
    throw new Error('No client_id or sub found in access token payload')
  }
}

export async function setupAmplifySession(
  username: string,
  accessToken: string,
  idToken: string,
  refreshToken: string,
  userPoolId: string,
  appClientId: string
) {
  // Create Cognito tokens
  const accessCognitoToken = new CognitoAccessToken({ AccessToken: accessToken })
  const idCognitoToken = new CognitoIdToken({ IdToken: idToken })
  const refreshCognitoToken = new CognitoRefreshToken({ RefreshToken: refreshToken })

  // Create a new user session with the tokens
  const session = new CognitoUserSession({
    AccessToken: accessCognitoToken,
    IdToken: idCognitoToken,
    RefreshToken: refreshCognitoToken
  })

  // Create a user pool object
  const userPool = new CognitoUserPool({
    UserPoolId: userPoolId,
    ClientId: appClientId
  })

  // Then create a new user object from that pool
  const user = new CognitoUser({
    Username: username,
    Pool: userPool
  })

  // Connect user to the session
  user.setSignInUserSession(session)

  // Set a refresh flag so when we navigate to dashboard
  // The app knows it needs to refresh to pick up the current logged in user
  window.localStorage.setItem('userData', 'refresh')
}
