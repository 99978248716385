'use client'

import { useRollbar } from '@rollbar/react'
import { useRouter, useSearchParams } from 'next/navigation'

import setUserTokens from '~/auth/utils'
import { LoadingMessage } from '~/components/Loaders'
import { useExchangeTokenMutation } from '~/data/api/client/auth'
import useFirstRender from '~/hooks/useFirstRender'
import routeDirectory from '~/navigation/routes'

export default function SSOCallbackPage() {
  const searchParams = useSearchParams()
  const code = searchParams.get('code')
  const router = useRouter()
  const rollbar = useRollbar()
  const [exchangeToken] = useExchangeTokenMutation()

  useFirstRender(() => {
    const fetchData = async () => {
      if (!code) {
        router.replace(routeDirectory.dashboard.root)
        return
      }

      const { data: tokenResponse } = await exchangeToken({
        code
      })

      try {
        if (tokenResponse) {
          const { id_token, access_token, refresh_token } = tokenResponse
          await setUserTokens(access_token, id_token, refresh_token)
          router.replace(routeDirectory.dashboard.root)
        } else {
          throw new Error('No token response for SSO exchange')
        }
      } catch (error) {
        // Notify Rollbar that the user session update failed
        rollbar.error('Error updating user session', { error })
        router.replace(routeDirectory.dashboard.root)
      }
    }

    fetchData()
  })

  return <LoadingMessage />
}
