import { useAuthenticator } from '@aws-amplify/ui-react'
import { useMemo } from 'react'

import type { UserModel } from '~/data/api/client'
import { useGetUserQuery } from '~/data/api/client'
import type { SynopUser } from '~/data/types/user'

import { useGetCurrentOrgUsers } from './organizations'
import { useTransformedDict } from './utils'

type MaybeSynopUser = SynopUser | undefined

export function useUser(userId?: string) {
  const result = useGetUserQuery({ id: userId ?? '' }, { skip: !userId })

  // Ensure the user returned from the hook matches the ID passed in. This is needed when the ID
  // changes or is cleared.
  const user = (result.data?.id === userId ? result.data : undefined) as MaybeSynopUser
  return { user, ...result }
}

export function useUserTokenMap(usersInput?: UserModel[]) {
  const { users: usersFetched, ...result } = useGetCurrentOrgUsers()

  const withAuthToken = useMemo(() => {
    const users = usersInput ?? usersFetched ?? []
    return users
      .filter((user) => user.rfidTags?.length ?? 0 > 0)
      .flatMap((user) =>
        user.rfidTags!.map((tag) => ({
          [tag.ocppTagValue as string]: user
        }))
      )
      .reduce((acc, curr) => ({ ...acc, ...curr }), {})
  }, [usersInput, usersFetched])

  return { userAuthTokens: useTransformedDict(withAuthToken, { caseInsensitive: true }), ...result }
}

export function useCurrentUser() {
  const { user } = useAuthenticator((context) => [context.user])
  const { user: currentUser, isLoading: isUserLoading } = useUser(user.userId)

  const isAdmin = useMemo(() => {
    return currentUser?.roles?.includes('Admin')
  }, [currentUser])

  return {
    user,
    synopUser: currentUser,
    isUserLoading,
    isAdmin
  }
}
